import { useForm } from "react-hook-form";
import React, { Fragment } from "react";

const AppointmentForm = () => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  });
  // const onSubmit = (data) => console.log(data);

  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "5abd487a-d3e0-4b8d-9c5a-df3b0b0cd6d1");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  return (
    <Fragment>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="name"
                required
                placeholder="Enter your name..."
                ref={register({ required: "Name is required" })}
              />
              {errors.name && <p>{errors.name.message}</p>}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="email"
                required
                placeholder="name@email.com"
                ref={register({
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "invalid email address",
                  },
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input
                className="form-control"
                type="tel"
                name="phone"
                required
                placeholder="Phone"
                ref={register({
                  required: "Phone is required",
                })}
              />
              {errors.phone && <p>{errors.phone.message}</p>}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group datepicker-group">
              <label
                htmlFor="datepicker"
                className="form-label icon icofont-calendar"
              >
                <input
                  className="form-control"
                  id="datepicker"
                  type="date"
                  name="date"
                  required
                  placeholder="Date"
                  ref={register({
                    required: "Date is required",
                  })}
                />
              </label>
              {errors.date && <p>{errors.date.message}</p>}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-0">
              <textarea
                name="message"
                required
                rows="7"
                placeholder="Your message here..."
                ref={register({
                  required: "Message is required",
                })}
              ></textarea>
              {errors.message && <p>{errors.message.message}</p>}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-0">
              <button className="btn btn-theme" type="submit">
                Make an appointment
              </button>
            </div>
          </div>
        </div>
      </form>
      <span>{result}</span>
    </Fragment>
  );
};

export default AppointmentForm;
