import React from "react";
import { Link } from "react-router-dom";

const ContactAddress = () => {
    return (
        <div className="contact-info-content">
            <div className="info-address">
                <h2 className="title">
                Argwings Kodhek Road,<span>Nairobi</span>
                </h2>
                <p>
                    Located at Nairobi Hospital Doctors Plaza, 3rd floor Suite 311
                    P.O. Box 1307-00502,
                    Argwings Kodhek Road, Nairobi
                </p>
                <Link to="/">
                    <span>Email:</span> millennium@africaonline.co.ke
                </Link>
            </div>
            <div className="brand-office">
                <div className="info-tem style-two">
                    <h6>Call directly:</h6>
                    <p>0728-500-120/0733-621-604</p>
                </div>
                {/* <div className="info-tem">
                    <h6>Brand Offices:</h6>
                    <p>
                        Allentown PA | Allanta, GA | Chicago, IL | Dallas, TX,
                        Edison, NJ | Houston, TX
                    </p>
                </div> */}
                <div className="info-tem mb-0">
                    <h6>Work Hours:</h6>
                    <p>Mon - Sat: 8.00 - 17.00, Sunday closed</p>
                </div>
            </div>
        </div>
    );
};

export default ContactAddress;
