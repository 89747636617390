import React from "react";

const GoogleMap = () => {
    return (
        // <iframe
        //     title="this is title"
        //     className="contact-map"
        //     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2136.986005919501!2d-73.9685579655238!3d40.75862446708152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258e4a1c884e5%3A0x24fe1071086b36d5!2sThe%20Atrium!5e0!3m2!1sen!2sbd!4v1585132512970!5m2!1sen!2sbd"
        //     aria-hidden="false"
        // ></iframe>
        <iframe
            title="this is title"
            className="contact-map"

            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d832.2980488860713!2d36.8035925!3d-1.2957145!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f11c2586d3ef1%3A0xe40aae3fb2469d26!2sMillenium%20Dental%20Services!5e1!3m2!1sen!2ske!4v1724310454626!5m2!1sen!2ske">
            aria-hidden="false"
        </iframe>
    );
};

export default GoogleMap;
