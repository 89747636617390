import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import PageTitleContainer from "../containers/global/page-title/index.jsx";
import Footer from "../layouts/footer";
import GalleryContainer from "../containers/Gallery";
import SmilesContainer from "../containers/smiles";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

const PatientinfoPage = () => {
  return (
    <React.Fragment>
      <Layout>
        <SEO title="Millenium Dental Services About" />
        <div className="wrapper">
          <Header />
          <div className="main-content site-wrapper-reveal">
            This page is under maintenance
          </div>

          {/* <div className="main-content site-wrapper-reveal">
            <GalleryContainer />
          </div>
          <div className="main-content site-wrapper-reveal">
            <SmilesContainer />
          </div> */}
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default PatientinfoPage;
