import React from "react";
import { useForm } from "react-hook-form";

const ContactForm = () => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  });
  // const onSubmit = (data) => console.log(data);

  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "bf2dd4ab-ac48-4ce3-aec2-15f6debd7ce1");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="name"
                required
                placeholder="Your Name"
                ref={register({ required: "Name is required" })}
              />
              {errors.name && <p>{errors.name.message}</p>}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="email"
                required
                placeholder="Email Address"
                ref={register({
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "invalid email address",
                  },
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="subject"
                required
                placeholder="Subject (optional)"
                ref={register({
                  required: "Subject is required",
                })}
              />
              {errors.subject && <p>{errors.subject.message}</p>}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-0">
              <textarea
                name="message"
                required
                rows="5"
                placeholder="Write your message here"
                ref={register({
                  required: "Message is required",
                })}
              ></textarea>
              {errors.message && <p>{errors.message.message}</p>}
            </div>
          </div>
          <div className="col-md-12 text-center">
            <div className="form-group mb-0">
              <button className="btn btn-theme btn-block" type="submit">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </form>
      <span>{result}</span>
    </>
  );
};

export default ContactForm;
